<template>
  <div class="work-index">
    <div class="top-date-box">
      <div class="mounth-date" @click="onBackDate">
        <div class="mounth">{{ bookDate.format("M") }}月</div>
        <div class="year">{{ bookDate.format("YYYY") }}</div>
      </div>
      <div class="date-list">
        <div
          v-for="(v, k) in dateList"
          :key="k"
          :class="{
            date: true,
            on: bookDate.format('D') === v.day,
          }"
          @click="onChangeDate(v)"
        >
          <div class="day">{{ v.day }} <span>号</span></div>
          <div class="week">{{ v.week }}</div>
        </div>
      </div>
      <div class="more-date" @click="showDatePicker">
        <span>更多日期</span>
        <icon-font type="icon-fanhui" class="more-icon" />
      </div>
    </div>

    <div class="order-info" v-if="orderInfo">
      就餐/预订：{{ orderInfo.total.orderNum }}单/{{
        orderInfo.total.tableNum
      }}桌/{{ orderInfo.total.peopleNum }}人
    </div>

    <div class="time-type">
      <div
        :class="{ item: true, on: chooseType === 1 }"
        @click="onChooseTimeType(1)"
      >
        <div class="top">
          <div class="type-name">
            <icon-font
              type="icon-weibiaoti-"
              style="
                color: rgb(250, 248, 134);
                font-size: 0.4rem;
                margin-right: 10px;
              "
            />
            <span>午餐</span>
          </div>

          <div class="type-info" v-if="orderInfo">
            就餐/预订：{{ orderInfo.noon.orderNum }}单/{{
              orderInfo.noon.tableNum
            }}桌/{{ orderInfo.noon.peopleNum }}人
          </div>
        </div>

        <div class="action-btns" v-if="chooseType === 1">
          <div class="btn yy" @click="$router.push({name: 'handlers-tables', query: {diningType: 1, bookDate: bookDate.format('YYYY-MM-DD')}})">
            <icon-font type="icon-yuyue" />
            预约
          </div>

          <div class="btn" @click="$router.push({name: 'handlers-orders', query: {diningType: 1, bookDate: bookDate.format('YYYY-MM-DD')}})">
            <icon-font type="icon-shenqingjilu" />
            订单
          </div>
        </div>
      </div>
      <div
        :class="{ item: true, on: chooseType === 2 }"
        @click="onChooseTimeType(2)"
      >
        <div class="top">
          <div class="type-name">
            <icon-font
              type="icon-yejing"
              style="
                color: rgb(142, 134, 250);
                font-size: 0.4rem;
                margin-right: 10px;
              "
            />
            <span>晚餐</span>
          </div>
          <div class="type-info" v-if="orderInfo">
            就餐/预订：{{ orderInfo.afterNoon.orderNum }}单/{{
              orderInfo.afterNoon.tableNum
            }}桌/{{ orderInfo.afterNoon.peopleNum }}人
          </div>
        </div>

        <div class="action-btns" v-if="chooseType === 2">
          <div class="btn yy" @click="$router.push({name: 'handlers-tables', query: {diningType: 2, bookDate: bookDate.format('YYYY-MM-DD')}})">
            <icon-font type="icon-yuyue" />
            预约
          </div>

          <div class="btn" @click="$router.push({name: 'handlers-orders', query: {diningType: 2, bookDate: bookDate.format('YYYY-MM-DD')}})">
            <icon-font type="icon-shenqingjilu" />
            订单
          </div>
        </div>
      </div>
    </div>

    <Footer />

    <van-popup v-model:show="show" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        :min-date="minDate"
        title="选择日期"
        @confirm="onChangePropDate"
      />
    </van-popup>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import Footer from './Footer'
import dayjs from 'dayjs'
import { Popup, DatetimePicker } from 'vant'
import 'vant/es/popup/style'
import 'vant/es/datetime-picker/style'
import { TableClass } from '@/apis/table'

const api = new TableClass()
export default defineComponent({
  components: {
    Footer,
    [Popup.name]: Popup,
    [DatetimePicker.name]: DatetimePicker
  },
  setup () {
    const currentDate = ref(new Date())
    const show = ref(false)
    const bookDate = ref(dayjs())
    const orderInfo = ref()

    const chooseType = ref(0)

    const onChangeDate = (e) => {
      bookDate.value = e.date

      getOrderInfo()
    }

    const showDatePicker = () => {
      show.value = true
    }

    const onChangePropDate = (e) => {
      show.value = false
      bookDate.value = dayjs(e)

      getOrderInfo()
    }

    const onBackDate = () => {
      currentDate.value = new Date()
      bookDate.value = dayjs()

      getOrderInfo()
    }

    const getOrderInfo = () => {
      api.orderInfo(bookDate.value.format('YYYY-MM-DD')).then((resp) => {
        orderInfo.value = resp
      })
    }

    const onChooseTimeType = (e) => {
      chooseType.value = e
    }

    getOrderInfo()

    return {
      currentDate,
      bookDate,
      show,
      minDate: new Date(),
      orderInfo,
      chooseType,

      onChangeDate,
      showDatePicker,
      onChangePropDate,
      onBackDate,
      onChooseTimeType
    }
  },

  computed: {
    dateList () {
      const cur = this.currentDate
      const weekName = ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
      const list = [
        {
          date: dayjs(cur),
          day: dayjs(cur).format('D'),
          week: weekName[dayjs(cur).day()]
        },
        {
          date: dayjs(cur).add(1, 'day'),
          day: dayjs(cur).add(1, 'day').format('D'),
          week: weekName[dayjs(cur).add(1, 'day').day()]
        },
        {
          date: dayjs(cur).add(2, 'day'),
          day: dayjs(cur).add(2, 'day').format('D'),
          week: weekName[dayjs(cur).add(2, 'day').day()]
        }
      ]
      return list
    }
  },

  created () {},
  methods: {}
})
</script>

<style lang="less" scoped>
.work-index {
  min-height: 100vh;
  background: #eee;
  .top-date-box {
    width: 100%;
    height: 1.5rem;
    background: #fff;
    padding: 0 1rem;
    position: relative;

    .mounth-date {
      position: absolute;
      top: 0.25rem;
      left: 0;
      width: 1rem;
      height: 1rem;
      text-align: center;
      .mounth {
        font-weight: 600;
        font-size: 0.32rem;
        color: #333;
      }
      .year {
        font-size: 0.2rem;
        color: rgb(142, 134, 250);
      }
    }

    .date-list {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .date {
        width: 1.7rem;
        height: 1rem;
        border: 1px solid #ccc;
        border-radius: 3px;
        position: relative;
        text-align: center;
        .day {
          font-size: 0.36rem;
          font-weight: 600;
          line-height: 0.6rem;
          span {
            font-weight: 400;
            font-size: 0.24rem;
          }
        }
        .week {
          font-size: 0.24rem;
          line-height: 0.3rem;
        }

        &.on {
          color: rgb(142, 134, 250);
          border-color: rgb(142, 134, 250);
          background: rgb(225, 224, 255);
          .day {
            color: #333;
          }
        }
      }
    }

    .more-date {
      position: absolute;
      top: 0.25rem;
      right: 0;
      width: 1rem;
      height: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.24rem;
      padding: 0.1rem;
      line-height: 0.25rem;
      .more-icon {
        transform: rotate(180deg);
        font-size: 0.3rem;
        color: rgb(142, 134, 250);
      }
    }
  }

  .order-info {
    padding: 0.1rem 0.2rem;
    color: #999;
    line-height: 0.5rem;
  }

  .time-type {
    padding: 0 0.2rem;
    .item {
      background: #fff;
      border-radius: 3px;
      border: 1px solid #fff;
      margin-bottom: 0.3rem;
      padding: 0.2rem;
      font-weight: 400;

      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .type-name {
          font-size: 0.36rem;
        }
        .type-info {
          font-size: 0.28rem;
        }
      }

      .action-btns {
        display: flex;
        justify-content: space-between;
        padding-top: 0.2rem;

        .btn {
          width: 3rem;
          height: 0.6rem;
          background: #838bfc;
          border-radius: 5px;
          border: 1px solid #2f2cf8;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          font-size: 0.28rem;

          &.yy {
            background: #e91f1f;
            border-color: #fc1515;
          }
        }
      }

      &.on {
        border-radius: 6px;
        border-color: rgb(142, 134, 250);
      }
    }
  }
}
</style>
